import { debounce } from 'lodash-es';
import { ComplexSymbolManager } from '@/mxgraph/ComplexSymbols/ComplexSymbolManager.class';
import { MxLayoutManager, MxCell, MxEventObject, MxGeometry, MxGraphLayout } from 'MxGraph';
import {
    OFFSET_TOP_BOTTOM,
    CELLS_GAP,
    SWIMLANE_NO_LABEL_FLAG,
    DEFAULT_CONTENT_COLUMN_HEIGHT,
    DEFAULT_CONTENT_COLUMN_WIDTH,
    OFFSET_LEFT_RIGHT,
} from './GridDiagram.constants';
import { isCommentCell } from '@/utils/bpm.mxgraph.utils';

class GridCellLayout extends MxGraphLayout {
    execute(targetCell) {
        debounce((targetCell) => {
            this.adjustHeight(targetCell);
        })(targetCell);
        this.adjustWidth(targetCell);
        this.applyLayout(targetCell);
    }

    private applyLayout(containerCell: MxCell) {
        const { width: parentWidth } = containerCell.getGeometry();

        const filtered = (containerCell.children.filter(this.filterSupported) as MxCell[]).sort((a, b) => {
            const geo1 = a.getGeometry();
            const geo2 = b.getGeometry();
            return geo1.y - geo2.y;
        });

        let x = 0;
        let y = OFFSET_TOP_BOTTOM; //Math.ceil((parentHeight - childrenHeight) / 2);

        for (const c of filtered) {
            const { width, height } = c.getGeometry();
            x = (parentWidth - width) / 2;
            const geo = new MxGeometry(x, y, width, height);
            y += height + CELLS_GAP;

            c.setGeometry(geo);
            const labelCell = ComplexSymbolManager.getComplexSymbolLabelCell(c);
            labelCell?.setGeometry(geo);
        }
        this.graph.view.clear(containerCell);
        this.graph.view.validate(containerCell);
    }

    private adjustHeight(containerCell: MxCell) {
        const getMaxRowHeight = (rowCell: MxCell) => {
            let result = DEFAULT_CONTENT_COLUMN_HEIGHT;
            rowCell?.children?.forEach((c) => {
                const supportedChildren = (c.children || []).filter(this.filterSupported);
                const next = this.getHeightSum(supportedChildren);

                result = Math.max(next, result);
            });

            return Math.max(DEFAULT_CONTENT_COLUMN_HEIGHT, result);
        };

        const { height: parentHeight } = containerCell.getGeometry();
        const adjustRowHeight = getMaxRowHeight(containerCell.parent);
        const delta = Math.ceil(adjustRowHeight - parentHeight);
        if (delta) {
            this.graph.setTableRowHeight(containerCell.parent, delta, true);
        }
    }
    private adjustWidth(containerCell: MxCell) {
        const getColumnChildrenWidths = (cell: MxCell): number[] => {
            let widths: number[] = [];
            var model = this.graph.getModel();
            const columnIndex = model.getChildCells(containerCell.getParent(), true).indexOf(containerCell);

            cell?.parent?.children?.forEach((rowCell, index) => {
                if (!rowCell.children) {
                    return;
                }

                const swimlineChildren = (rowCell.children[columnIndex]?.children || [])
                    .filter(this.filterSupported)
                    .map((c) => {
                        return c.getGeometry().width;
                    });
                widths = [...widths, ...swimlineChildren];
            });

            return widths;
        };

        const getMaxRowWidth = (rowCell: MxCell) => {
            let result = DEFAULT_CONTENT_COLUMN_WIDTH;
            const widths = getColumnChildrenWidths(rowCell);
            widths.forEach((width) => {
                result = Math.max(result, width);
            });

            if (!widths.length) {
                return -1;
            }

            return result;
        };

        const { width: parentWidth } = containerCell.getGeometry();
        const adjustRowWidth = getMaxRowWidth(containerCell.parent);

        if (adjustRowWidth < 0) {
            return;
        }
        const delta = adjustRowWidth + OFFSET_LEFT_RIGHT - parentWidth;
        if (delta) {
            this.graph.setTableColumnWidth(containerCell, delta, true);
        }
    }
    private getHeightSum(children: MxCell[]) {
        return (
            children.reduce((cur, aac) => {
                return cur + aac.getGeometry().height + CELLS_GAP;
            }, 0) + OFFSET_TOP_BOTTOM
        );
    }
    private filterSupported(cell: MxCell): boolean {
        if (cell.isEdge()) {
            return false;
        }
        if (isCommentCell(cell)) {
            return false;
        }
        if (!ComplexSymbolManager.isComplexSymbolCell(cell)) {
            return true;
        }
        return ComplexSymbolManager.isComplexSymbolRootCell(cell);
    }
}

export function createGridCellLayout(graph) {
    const layoutMgr = new MxLayoutManager(graph);

    layoutMgr.getLayout = (parentCell: MxCell, evt: MxEventObject, child?: MxCell) => {
        const style = parentCell?.getStyle() || '';
        if (style.includes(SWIMLANE_NO_LABEL_FLAG) && parentCell?.children?.length > 0) {
            return new GridCellLayout(graph);
        }

        return null;
    };

    return layoutMgr;
}
