// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".SearchPath__searchPath__pdscm{width:-webkit-fit-content;width:-moz-fit-content;width:fit-content;display:-webkit-box;display:-ms-flexbox;display:flex;margin-bottom:10px;cursor:pointer}.SearchPath__path__uPUN_{margin-left:10px}.SearchPath__icon__urCFk{display:-webkit-box;display:-ms-flexbox;display:flex;height:22px;margin-right:10px}.SearchPath__icon__urCFk svg{margin:auto}.SearchPath__pathsContainer__x3sPt{max-height:200px;overflow:scroll}.SearchPath__textContainer__DsjNK{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:baseline;-ms-flex-align:baseline;align-items:baseline}", "",{"version":3,"sources":["webpack://./src/modules/Search/SearchPath/SearchPath.scss"],"names":[],"mappings":"AAAA,+BACI,yBAAA,CAAA,sBAAA,CAAA,iBAAA,CACA,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,kBAAA,CACA,cAAA,CAGJ,yBACI,gBAAA,CAGJ,yBACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,WAAA,CAIA,iBAAA,CAHA,6BACI,WAAA,CAKR,mCACI,gBAAA,CACA,eAAA,CAGJ,kCACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,0BAAA,CAAA,uBAAA,CAAA,oBAAA","sourcesContent":[".searchPath {\r\n    width: fit-content;\r\n    display: flex;\r\n    margin-bottom: 10px;\r\n    cursor: pointer;\r\n}\r\n\r\n.path {\r\n    margin-left: 10px;\r\n}\r\n\r\n.icon {\r\n    display: flex;\r\n    height: 22px;\r\n    svg {\r\n        margin: auto;\r\n    }\r\n    margin-right: 10px;\r\n}\r\n\r\n.pathsContainer {\r\n    max-height: 200px;\r\n    overflow: scroll;\r\n}\r\n\r\n.textContainer {\r\n    display: flex;\r\n    align-items: baseline;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"searchPath": "SearchPath__searchPath__pdscm",
	"path": "SearchPath__path__uPUN_",
	"icon": "SearchPath__icon__urCFk",
	"pathsContainer": "SearchPath__pathsContainer__x3sPt",
	"textContainer": "SearchPath__textContainer__DsjNK"
};
export default ___CSS_LOADER_EXPORT___;
