import { TRootState } from '../reducers/root.reducer.types';
import { createSelector } from 'reselect';
import { NodeId, Symbol } from '../serverapi/api';
import { TSymbolState } from '../reducers/symbol.reducer.types';
import { TreeSelectors } from './tree.selectors';

const symbolStateSelector = (state: TRootState) => state.symbol;

export namespace SymbolSelectors {
    export const all = createSelector(symbolStateSelector, (state) => state.data);

    export const byNodeIdAndSymbolId = (nodeId: NodeId, symbolId?: string) =>
        createSelector(TreeSelectors.presetById(nodeId), all, (presetId, symbols) => {
            const { serverId } = nodeId;
            if (presetId && symbolId) return symbols.get({ presetId, serverId, symbolId });

            return undefined;
        });

    export const byServerIdPresetId = (serverId: string, presetId: string) => {
        return createSelector<TRootState, TSymbolState, Symbol[]>(symbolStateSelector, (state): Symbol[] =>
            state.data
                .entries()
                .filter((e) => e[0].serverId === serverId && e[0].presetId === presetId)
                .map((e) => e[1]),
        );
    };

    export const byServerIdPresetIds = (serverId: string, presetIds: string[]) => {
        return createSelector<TRootState, TSymbolState, Symbol[]>(symbolStateSelector, (state): Symbol[] =>
            state.data
                .entries()
                .filter((e) => e[0].serverId === serverId && presetIds.includes(e[0].presetId))
                .map((e) => e[1]),
        );
    };

    export const byId = (symbol: { id: string; serverId: string }, presetId: string) =>
        createSelector<TRootState, TSymbolState, Symbol | undefined>(
            // todo meta id
            symbolStateSelector,
            (state) => state.data.get({ symbolId: symbol.id, serverId: symbol.serverId, presetId }),
        );

    export const byObjectType = (serverId: string, presetId: string, objectTypeId: string) =>
        createSelector(byServerIdPresetId(serverId, presetId), (state) =>
            state.filter((symbol: Symbol) => objectTypeId === symbol.objectType),
        );

    export const chooseModelTypeSymbols = (modelTypeSymbols: Symbol[], symbols: Symbol[]): Symbol[] =>
        modelTypeSymbols.reduce((acc, item) => {
            const modelSymbol = symbols.find((s) => s.id === item.id);
            if (modelSymbol) acc.push(modelSymbol);

            return acc;
        }, [] as Symbol[]);
}
