import { defineMessages } from 'react-intl';

export default defineMessages({
    copyLinkTitle: {
        id: 'copyDialog.copyLinkTitle',
        defaultMessage: 'Ссылка на элемент',
    },
    copyIdTitle: {
        id: 'copyDialog.copyIdTitle',
        defaultMessage: 'Ссылка на ID элемента',
    },
    copyModelImageLink: {
        id: 'copyDialog.copyModelImageLink',
        defaultMessage: 'Ссылка на изображения модели',
    },
    copyPath: {
        id: 'copyDialog.copyPath',
        defaultMessage: 'Путь к элементу',
    },
});
