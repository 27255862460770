import React, { FC, useRef, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import XSpreadsheet from 'x-data-spreadsheet';
import 'x-data-spreadsheet/dist/xspreadsheet.css';
import { debounce } from 'lodash-es';
import { ISpreadsheetNode } from '../../models/bpm/bpm-model-impl.types';
import { TabsSelectors } from '../../selectors/tabs.selectors';
import { SpreadsheetCell, SpreadsheetStyle } from '../../serverapi/api';
import { EditorMode } from '../../models/editorMode';
import { spreadsheetSave, spreadsheetUpdate } from '../../actions/entities/spreadsheet.actions';
import theme from './Spreadsheet.scss';
import { SpreadsheetSelectors } from '../../selectors/entities/spreadsheet.selectors';
import { instancesXSpreadsheetMap } from '../../xspreadsheet/xspreadsheet-instances-map';
import {
    getCellDataFromSpreadsheetRows,
    getDataFromSpreadsheet,
    getSpreadsheetStyle,
} from '../../utils/spreadsheet.utils';
import { TRows, TSpreadsheet, TSpreadsheetProps } from './spreadsheep.types';
import { useAutoSave } from '@/hooks/useAutoSave';
import { useRelock } from '@/hooks/useRelock';

export const Spreadsheet: FC<TSpreadsheetProps> = ({ tab }) => {
    const content = tab.content as ISpreadsheetNode;
    const { nodeId } = content;
    const spreadsheet: ISpreadsheetNode | undefined = useSelector(SpreadsheetSelectors.byId(nodeId));
    const editorMode = useSelector(TabsSelectors.getEditorModeById(nodeId));
    const isEditMode = editorMode === EditorMode.Edit;
    const dispatch = useDispatch();
    const [isFirstRender, setIsFirstRender] = useState<boolean>(true);
    const spreadsheetContainerRef = useRef<HTMLDivElement>(null);

    const spreadheetSaveHandler = () => {
        if (spreadsheet) {
            dispatch(
                spreadsheetSave(spreadsheet.serverId, {
                    body: spreadsheet,
                }),
            );
        }
    };

    useAutoSave({
        nodeId,
        isEditMode,
        dependences: [spreadsheet],
        callback: spreadheetSaveHandler,
    });

    useRelock(isEditMode, nodeId, 'SPREADSHEET');

    const debouncedOnChange = debounce((modifiedData: TSpreadsheet): void => {
        if (!spreadsheet) return;

        const cells: SpreadsheetCell[] = getCellDataFromSpreadsheetRows(modifiedData.rows as TRows);
        const style: SpreadsheetStyle = getSpreadsheetStyle(modifiedData);
        dispatch(
            spreadsheetUpdate({
                ...spreadsheet,
                cells,
                style,
            }),
        );
    }, 500);

    useEffect(() => {
        const spreadsheetContainer = spreadsheetContainerRef.current;
        const mode = editorMode === EditorMode.Edit ? 'edit' : 'read';
        let sheet: XSpreadsheet | null = new XSpreadsheet(`#spreadsheet-${nodeId.id}-${nodeId.repositoryId}`, {
            showToolbar: false,
            showContextmenu: false,
            showBottomBar: false,
            mode,
            view: {
                height: () => spreadsheetContainer?.clientHeight || 0,
                width: () => spreadsheetContainer?.clientWidth || 0,
            },
            style: {
                bgcolor: 'white',
                align: 'left',
                valign: 'top',
                textwrap: true,
                strike: false,
                underline: false,
                color: 'black',
                font: {
                    name: 'SegoeUI' as 'Helvetica',
                    size: 12,
                    bold: false,
                    italic: false,
                },
            },
        });
        const dataForXSpreadsheet: TSpreadsheet = getDataFromSpreadsheet(spreadsheet);
        sheet.loadData(dataForXSpreadsheet).change(debouncedOnChange);
        instancesXSpreadsheetMap.set(nodeId, sheet);

        if (!isFirstRender && spreadsheet) {
            dispatch(
                spreadsheetSave(spreadsheet.serverId, {
                    body: spreadsheet,
                }),
            );
        }

        return () => {
            sheet = null;
            if (spreadsheetContainer) {
                spreadsheetContainer.innerHTML = '';
                instancesXSpreadsheetMap.delete(nodeId);
            }
        };
    }, [editorMode]);

    useEffect(() => {
        setIsFirstRender(false);
    }, []);

    return (
        <div
            data-test="table-editor_container"
            id={`spreadsheet-${nodeId.id}-${nodeId.repositoryId}`}
            className={theme.spreadsheetContainer}
            ref={spreadsheetContainerRef}
        />
    );
};
