import { defineMessages } from 'react-intl';

export default defineMessages({
    Name: {
        id: 'ObjectProperties.Name',
        defaultMessage: 'Наименование',
    },
    'Model Type': {
        id: 'ObjectProperties.ModelType',
        defaultMessage: 'Тип модели',
    },
    'Object Type': {
        id: 'ObjectProperties.ObjectType',
        defaultMessage: 'Тип объекта',
    },
    'Edge Type': {
        id: 'ObjectProperties.EdgeType',
        defaultMessage: 'Тип связи',
    },
    'Source object Name': {
        id: 'ObjectProperties.SourceObjectName',
        defaultMessage: 'Объект-источник',
    },
    'Target object Name': {
        id: 'ObjectProperties.TargetObjectName',
        defaultMessage: 'Объект-цель',
    },
    Description: {
        id: 'ObjectProperties.Description',
        defaultMessage: 'Описание',
    },
    Version: {
        id: 'ObjectProperties.Version',
        defaultMessage: 'Версия',
    },
    'Created At': {
        id: 'ObjectProperties.CreatedAt',
        defaultMessage: 'Дата создания',
    },
    'Created By': {
        id: 'ObjectProperties.CreatedBy',
        defaultMessage: 'Создал',
    },
    'Deleted At': {
        id: 'ObjectProperties.DeletedAt',
        defaultMessage: 'Дата удаления',
    },
    'Deleted By': {
        id: 'ObjectProperties.DeletedBy',
        defaultMessage: 'Удалил',
    },
    'Updated At': {
        id: 'ObjectProperties.UpdatedAt',
        defaultMessage: 'Дата изменения',
    },
    'Updated By': {
        id: 'ObjectProperties.UpdatedBy',
        defaultMessage: 'Изменил',
    },
    'Line Type': {
        id: 'ObjectProperties.LineType',
        defaultMessage: 'Тип линии',
    },
    Direction: {
        id: 'ObjectProperties.Direction',
        defaultMessage: 'Направленность связи',
    },
    'Linked Object Types': {
        id: 'ObjectProperties.LinkedObjectTypes',
        defaultMessage: 'Типы объектов',
    },
    'Script language': {
        id: 'ScriptProperties.scriptLanguage',
        defaultMessage: 'Скриптовый язык',
    },
    'Element Type': {
        id: 'ScriptProperties.elementType',
        defaultMessage: 'Тип элемента',
    },
    'Start marker': {
        id: 'EdgeProperties.startArrow',
        defaultMessage: 'Наконечник старта',
    },
    'End marker': {
        id: 'EdgeProperties.endArrow',
        defaultMessage: 'Наконечник конца',
    },
    Confidential: {
        id: 'CommonProperties.confidential',
        defaultMessage: 'Конфиденциальность',
    },
    TradeSecret: {
        id: 'CommonProperties.tradeSecret',
        defaultMessage: 'Коммерческая тайна',
    },
    PersonalData: {
        id: 'CommonProperties.personalData',
        defaultMessage: 'Персональные данные',
    },
    'Folder type': {
        id: 'CommonProperties.folderType',
        defaultMessage: 'Тип папки',
    },
    'Symbol type': {
        id: 'CommonProperties.Symbol',
        defaultMessage: 'Тип символа',
    },
    UserEditDisabled: {
        id: 'CommonProperties.UserEditDisabled',
        defaultMessage: 'Запрещено редактирование пользователю',
    },
    ScriptEngineEditDisabled: {
        id: 'CommonProperties.ScriptEngineEditDisabled',
        defaultMessage: 'Запрещено редактирование скрипту',
    },
    'Published By': {
        id: 'ObjectProperties.PublishedBy',
        defaultMessage: 'Опубликовал',
    },
    'Published At': {
        id: 'ObjectProperties.PublishedAt',
        defaultMessage: 'Дата публикации',
    },
    file: {
        id: 'ObjectProperties.file',
        defaultMessage: 'Имя файла',
    },
    folder: {
        id: 'ObjectProperties.folder',
        defaultMessage: 'Имя папки',
    },
    model: {
        id: 'ObjectProperties.model',
        defaultMessage: 'Имя модели',
    },
    object: {
        id: 'ObjectProperties.object',
        defaultMessage: 'Имя объекта',
    },
    edge: {
        id: 'ObjectProperties.edge',
        defaultMessage: 'Имя линии связи',
    },
    dataBase: {
        id: 'ObjectProperties.dataBase',
        defaultMessage: 'Имя базы данных',
    },
    script: {
        id: 'ObjectProperties.script',
        defaultMessage: 'Имя скрипта',
    },
    simulation: {
        id: 'ObjectProperties.simulation',
        defaultMessage: 'Имя имитацинной модели',
    },
    report: {
        id: 'ObjectProperties.report',
        defaultMessage: 'Имя справочника',
    },
});
