import { TServerEntity } from '../models/entities.types';
import { ServerSelectors } from '../selectors/entities/server.selectors';
import { put, select, takeEvery } from 'redux-saga/effects';
import { COPY_ITEM_LINK, COPY_MODEL_IMAGE_LINK } from '../actionsTypes/modelLink.actionTypes';
import { TCopyLinkAction, TCopyModelImageLinkAction } from '../actions/copyLink.actions.types';
import { showNotificationByType } from '../actions/notification.actions';
import { NotificationType } from '../models/notificationType';
import { openDialog } from '../actions/dialogs.actions';
import { DialogType } from '../modules/DialogRoot/DialogRoot.constants';
import { ExternalLinkBLLService } from '../services/bll/ExternalLinkBLLService';
import { PublicLinkBLLService } from '../services/bll/PublicLinkBLLService';
import { TCopyContent } from '../modules/CopyDialog/CopyDialog.types';
import { getAuthorizationMethod } from '@/selectors/authorization.selectors';
import { LoginResponseAuthorizationMethodEnum, SystemPropertiesActiveAuthorizationMethodsEnum } from '@/serverapi/api';
import { SystemPropertiesSelectors } from '@/selectors/systemProperties.selectors';
import { TLinkType } from '@/services/bll/ExternalLinkBLLService.types';
import { Links } from './utils.types';

function* copyLink({ payload: { nodeId, type, elementId } }: TCopyLinkAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const authMethod: LoginResponseAuthorizationMethodEnum | undefined = yield select(getAuthorizationMethod);
    const activeAuthorizationMethods: SystemPropertiesActiveAuthorizationMethodsEnum[] | undefined = yield select(
        SystemPropertiesSelectors.getActiveAuthorizationMethods,
    );
    const linkType: TLinkType = ExternalLinkBLLService.getLinkTypeByAuth(authMethod, activeAuthorizationMethods);
    const externalLink: string = ExternalLinkBLLService.createExternalLink(
        server.url,
        type,
        nodeId.repositoryId,
        nodeId.id,
        linkType,
        elementId,
    );
    const copyContent: TCopyContent = [Links.ObjectTypeLink, externalLink];

    yield clipboardWriteText(externalLink, copyContent);
}

function* copyModelImageLink({ payload: { nodeId } }: TCopyModelImageLinkAction) {
    const server: TServerEntity = yield select(ServerSelectors.server(nodeId.serverId));
    const publicLink: string = PublicLinkBLLService.createPublicLink(server.url, nodeId.repositoryId, nodeId.id);
    const copyContent: TCopyContent = [Links.ModelImageLink, publicLink];

    yield clipboardWriteText(publicLink, copyContent);
}

function* clipboardWriteText(link: string, copyContent: TCopyContent) {
    if (navigator.clipboard) {
        try {
            yield window.navigator.clipboard.writeText(link);
            yield put(showNotificationByType(NotificationType.COPY_ITEM_LINK));
        } catch (e) {
            yield put(showNotificationByType(NotificationType.COPY_ITEM_LINK_ERROR));
            yield put(openDialog(DialogType.COPY_ITEM_DIALOG, { content: copyContent }));
        }
    } else {
        yield put(showNotificationByType(NotificationType.COPY_GUID_FAIL));
        yield put(openDialog(DialogType.COPY_ITEM_DIALOG, { content: copyContent }));
    }
}

export function* modelLinkSaga() {
    yield takeEvery(COPY_ITEM_LINK, copyLink);
    yield takeEvery(COPY_MODEL_IMAGE_LINK, copyModelImageLink);
}
