import { LocalesService } from '../../services/LocalesService';
import { AttributeValue, NodeTypeEnum } from '../../serverapi/api';
import { TValueTypeEnum } from '../ValueTypeEnum.types';
import { IPropertyDescriptor, TGetterFn, TPropertyValue, TSetterFn } from './property-descriptor.types';
import propertyMessages from './property.messages';

export const DefaultGetterFn =
    (key: string): TGetterFn =>
    (obj: {}) => {
        return {
            id: key,
            typeId: 'SYSTEM',
            valueType: 'STRING',
            value: obj[key],
        } as AttributeValue;
    };

export const DefaultSetterFn =
    (key: string, editable: boolean): TSetterFn =>
    (obj: {}, val: any) => {
        if (editable) {
            obj[key] = val;
        }
    }; // tslint:disable-line:no-any

export class TPropertyDescriptor implements IPropertyDescriptor {
    // eslint-disable-next-line no-useless-constructor
    constructor(
        readonly key: string,
        readonly title: string,
        readonly type: TValueTypeEnum,
        readonly editable: boolean,
        readonly system: boolean,
        readonly getter: TGetterFn = DefaultGetterFn(key),
        readonly setter: TSetterFn = DefaultSetterFn(key, editable),
        readonly dynamic: boolean = false,
        readonly typeId: string = '',
    ) {}

    buildNavigatorPropertyValue(obj: {} | undefined, locale: string): TPropertyValue {
        const value = this.getValue(obj, locale);

        return { descriptor: this, value };
    }

    getValue(obj: {} | undefined, locale: string): any {
        // tslint:disable-line:no-any
        if (obj) {
            return this.getter(obj, locale);
        }

        return undefined;
    }

    setValue(obj: {}, value: any) {
        // tslint:disable-line:no-any
        this.setter(obj, value);
    }

    isDefined(obj: {} | undefined, locale: string): boolean {
        if (obj) {
            return this.getter(obj, locale) !== undefined;
        }

        return false;
    }

    private getNameTitleByNodeType(nodeType: NodeTypeEnum): string {
        const intl = LocalesService.useIntl();
        switch (nodeType) {
            case 'FILE': {
                return intl.formatMessage(propertyMessages.file);
            }
            case 'FILE_FOLDER':
            case 'FOLDER':
            case 'SCRIPT_FOLDER': {
                return intl.formatMessage(propertyMessages.folder);
            }
            case 'MODEL':
            case 'MATRIX':
            case 'SPREADSHEET':
            case 'DASHBOARD':
            case 'WIKI':
            case 'KANBAN': {
                return intl.formatMessage(propertyMessages.model);
            }
            case 'OBJECT': {
                return intl.formatMessage(propertyMessages.object);
            }
            case 'EDGE': {
                return intl.formatMessage(propertyMessages.edge);
            }
            case 'DB': {
                return intl.formatMessage(propertyMessages.dataBase);
            }
            case 'SCRIPT': {
                return intl.formatMessage(propertyMessages.script);
            }
            case 'SIMULATION': {
                return intl.formatMessage(propertyMessages.simulation);
            }
            case 'REPORT': {
                return intl.formatMessage(propertyMessages.report);
            }
            default: {
                return intl.formatMessage(propertyMessages.Name);
            }
        }
    }

    getTitle(nodeType?: NodeTypeEnum): string {
        const intl = LocalesService.useIntl();

        if (this.title === 'Name' && nodeType) {
            return this.getNameTitleByNodeType(nodeType);
        }

        return propertyMessages[this.title] ? intl.formatMessage(propertyMessages[this.title]) : this.title;
    }
}
