import React from 'react';
import { Tooltip } from 'antd';

import { TObjectPathProps } from './ObjectPath.types';

import theme from './ObjectPath.scss';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import icCopy from '@/resources/icons/ic-copy.svg';

const maxVisiblePathLength = 137;

export const ObjectPath = ({ path, onClick, onCopy }: TObjectPathProps) => {
    const isOverflow = path.length > maxVisiblePathLength;
    const visibleText = isOverflow ? `${path.slice(0, maxVisiblePathLength - 1)}...` : path;
    const tooltipText = isOverflow ? path : undefined;

    return (
        <div className={theme.objectPathContainer}>
            <Tooltip title={tooltipText}>
                <span onClick={onClick} className={theme.objectPath}>
                    {visibleText}
                </span>
            </Tooltip>
            <Button onClick={onCopy} visualStyle={{ type: 'text' }} icon={icCopy}></Button>
        </div>
    );
};
