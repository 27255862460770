import { defineMessages } from 'react-intl';

export default defineMessages({
    linkHint: {
        id: 'EditableText.linkHint',
        defaultMessage: 'Не поддерживаются ссылки, скопированные из файлового менеджера:',
    },
    others: {
        id: 'EditableText.others',
        defaultMessage: 'и иные',
    },
});
