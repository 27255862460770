import React from 'react';
import { injectIntl, WrappedComponentProps } from 'react-intl';
import messages from '../messages/userManagment.messages';
import { UserDTO } from '../../../../serverapi/api';
import { Form, Input, Row, Col, Select, FormInstance, Checkbox } from 'antd';
import formMessages from '../../../../models/formDefault.messages';
import { SUPERADMIN_LOGIN } from '../../data/admintool';
import { Button } from '@/modules/UIKit/components/Button/Button.component';
import theme from './UserMainDataEditing.scss';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type TUserMainDataEditingProps = {
    userData: UserDTO;
    isNewUser: boolean;
    formRef: React.RefObject<FormInstance>;
    onShowSessions: (userId: number) => void;
    onChangeIsTechnical: (val: boolean) => void;
    isTechnical: boolean | undefined;
};

type TUserMainDataEditingFullProps = TUserMainDataEditingProps & WrappedComponentProps;

class UserMainDataEditing extends React.Component<TUserMainDataEditingFullProps> {
    selectContainerRef = React.createRef<HTMLDivElement>();
    isFieldsSet = false;

    componentDidUpdate() {
        // Попадаем в условие только один раз - устанавливаем все необходимые поля.
        if (!this.isFieldsSet) {
            const { formRef, userData } = this.props;

            formRef.current?.setFieldsValue({
                blocked: userData.blocked ? 'true' : 'false',
                email: userData.email,
                middleName: userData.middleName,
                company: userData.company,
                department: userData.department,
                position: userData.position,
                description: userData.description,
            });
            this.isFieldsSet = true;
        }
    }

    showSessions = () => {
        if (this.props.userData.id) {
            this.props.onShowSessions(this.props.userData.id);
        }
    };

    render() {
        const { intl, formRef, userData, isNewUser } = this.props;
        const isSuperadmin = userData && userData.login === SUPERADMIN_LOGIN;

        return (
            <div ref={this.selectContainerRef} style={{ position: 'relative' }} className={theme.container}>
                <h3>{intl.formatMessage(messages.userMainData)}</h3>
                <Form labelCol={{ span: 24 }} ref={formRef}>
                    <div>
                        <Row gutter={16}>
                            <Col style={{ marginTop: '32px' }} span={12}>
                                <Button
                                    dataTest="user-data-editing_session-history-button"
                                    key="ok"
                                    size="large"
                                    visualStyle="primary"
                                    disabled={!userData.id}
                                    onClick={this.showSessions}
                                >
                                    {intl.formatMessage(messages.showSessionsLabel)}
                                </Button>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    labelCol={{ span: 24 }}
                                    label={intl.formatMessage(messages.blockedColumn)}
                                    name="blocked"
                                    initialValue={userData.blocked ? 'true' : 'false'}
                                >
                                    <Select
                                        data-test="userList_filter-status_user-edit"
                                        style={{ maxWidth: '165px' }}
                                        disabled={isSuperadmin}
                                        getPopupContainer={() => this.selectContainerRef.current || document.body}
                                    >
                                        <Select.Option data-test="user-edit_select-status-notBlocked" value="false">
                                            {intl.formatMessage(messages.notBlocked)}
                                        </Select.Option>
                                        <Select.Option data-test="user-edit_select-status-Blocked" value="true">
                                            {intl.formatMessage(messages.blocked)}
                                        </Select.Option>
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={intl.formatMessage(messages.loginPlaceholder)}
                                    name="login"
                                    initialValue={userData.login}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: intl.formatMessage(formMessages.fieldRequiredError),
                                        },
                                    ]}
                                >
                                    <Input
                                        data-test="user-main-data-editing_login-input"
                                        autoComplete="off"
                                        placeholder={intl.formatMessage(messages.loginPlaceholder)}
                                        disabled={!isNewUser}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item label={intl.formatMessage(messages.pswPlaceholder)} name="password">
                                    <Input.Password
                                        data-test="user-main-data-editing_password-input"
                                        autoComplete="new-password"
                                        placeholder={intl.formatMessage(messages.pswPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={intl.formatMessage(messages.emailPlaceholder)}
                                    name="email"
                                    initialValue={userData.email}
                                >
                                    <Input
                                        data-test="user-main-data-editing_email-input"
                                        type="email"
                                        autoComplete="off"
                                        placeholder={intl.formatMessage(messages.emailPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={8}>
                                <Form.Item
                                    label={intl.formatMessage(messages.lnamePlaceholder)}
                                    name="lastName"
                                    initialValue={userData.lastName}
                                >
                                    <Input
                                        data-test="user-main-data-editing_lastname-input"
                                        autoComplete="off"
                                        placeholder={intl.formatMessage(messages.lnamePlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={intl.formatMessage(messages.fnamePlaceholder)}
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: intl.formatMessage(formMessages.fieldRequiredError),
                                        },
                                    ]}
                                    initialValue={userData.firstName}
                                    name="firstName"
                                >
                                    <Input
                                        data-test="user-main-data-editing_firstname-input"
                                        autoComplete="off"
                                        placeholder={intl.formatMessage(messages.fnamePlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item
                                    label={intl.formatMessage(messages.midllenamePlaceholder)}
                                    name="middleName"
                                    initialValue={userData.middleName}
                                >
                                    <Input
                                        data-test="user-main-data-editing_middlename-input"
                                        autoComplete="off"
                                        placeholder={intl.formatMessage(messages.midllenamePlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={intl.formatMessage(messages.companyPlaceholder)}
                                    name="company"
                                    initialValue={userData.company}
                                >
                                    <Input
                                        data-test="user-main-data-editing_company-input"
                                        placeholder={intl.formatMessage(messages.companyPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={intl.formatMessage(messages.departmentPlaceholder)}
                                    name="department"
                                    initialValue={userData.department}
                                >
                                    <Input
                                        data-test="user-main-data-editing_department-input"
                                        placeholder={intl.formatMessage(messages.departmentPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={intl.formatMessage(messages.positionPlaceholder)}
                                    name="position"
                                    initialValue={userData.position}
                                >
                                    <Input
                                        data-test="user-main-data-editing_position-input"
                                        placeholder={intl.formatMessage(messages.positionPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label={intl.formatMessage(messages.descPlaceholder)}
                                    name="description"
                                    initialValue={userData.description}
                                >
                                    <Input.TextArea
                                        rows={4}
                                        placeholder={intl.formatMessage(messages.descPlaceholder)}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item className={theme.checkboxContainer}>
                                    <Checkbox
                                        checked={this.props.isTechnical}
                                        disabled={isSuperadmin}
                                        onChange={(e: CheckboxChangeEvent) =>
                                            this.props.onChangeIsTechnical(e.target.checked)
                                        }
                                    >
                                        {intl.formatMessage(messages.technicalAccount)}
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </div>
                </Form>
            </div>
        );
    }
}

export default injectIntl(UserMainDataEditing);
