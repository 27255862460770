import { TWorkspaceTab } from '../models/tab.types';
import { BPMMxGraph } from '../mxgraph/bpmgraph';
import { NodeId } from '../serverapi/api';
import { ApiBundle } from '../services/api/api-bundle';

export interface IModelContext {
    graph: BPMMxGraph;
    schema: TWorkspaceTab;
    api: ApiBundle;
    nodeId: NodeId;
    notationId: string;
    parentNodeId: NodeId;
}

export enum Links {
    ModelImageLink = 'modelImageLink',
    ObjectTypeLink = 'link',
    Path = 'path',
}
