// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserMainDataEditing__container__Lp911 .ant-form-item-row>.ant-form-item-label>label.ant-form-item-required{padding-left:8px;top:0}.UserMainDataEditing__container__Lp911 .ant-form-item-row>.ant-form-item-label>label.ant-form-item-required::before{top:0;left:0;position:absolute}.UserMainDataEditing__checkboxContainer___Fxgc>div{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-orient:vertical;-webkit-box-direction:reverse;-ms-flex-direction:column-reverse;flex-direction:column-reverse}.UserMainDataEditing__checkboxContainer___Fxgc>div .ant-form-item-label label{margin-left:30px}", "",{"version":3,"sources":["webpack://./src/modules/AdminTools/UserManagement/components/UserMainDataEditing.scss"],"names":[],"mappings":"AAEQ,4GACI,gBAAA,CACA,KAAA,CACA,oHACI,KAAA,CACA,MAAA,CACA,iBAAA,CAMhB,mDACI,mBAAA,CAAA,mBAAA,CAAA,YAAA,CACA,2BAAA,CAAA,6BAAA,CAAA,iCAAA,CAAA,6BAAA,CAGQ,8EACI,gBAAA","sourcesContent":[".container {\r\n    :global {\r\n        .ant-form-item-row > .ant-form-item-label > label.ant-form-item-required {\r\n            padding-left: 8px;\r\n            top: 0;\r\n            &::before {\r\n                top: 0;\r\n                left: 0;\r\n                position: absolute;\r\n            }\r\n        }\r\n    }\r\n}\r\n\r\n.checkboxContainer > div {\r\n    display: flex;\r\n    flex-direction: column-reverse;\r\n    :global {\r\n        .ant-form-item-label {\r\n            label {\r\n                margin-left: 30px;\r\n            }\r\n        }\r\n    }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "UserMainDataEditing__container__Lp911",
	"checkboxContainer": "UserMainDataEditing__checkboxContainer___Fxgc"
};
export default ___CSS_LOADER_EXPORT___;
