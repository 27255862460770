import { defineMessages } from 'react-intl';

export default defineMessages({
    eventFormName: {
        id: 'EventDialog.eventFormName',
        defaultMessage: 'Создать новое событие',
    },
    eventRunStrategyRequiredError: {
        id: 'EventDialog.eventRunStrategyRequiredError',
        defaultMessage: 'Введите имя стратегии',
    },
    eventActionsRequiredError: {
        id: 'EventDialog.eventActionsRequiredError',
        defaultMessage: 'Введите имя действия',
    },
    eventFormConfirmButton: {
        id: 'EventDialog.eventFormConfirmButton',
        defaultMessage: 'Сохранить',
    },
    eventFormDeclineButton: {
        id: 'EventDialog.eventFormDeclineButton',
        defaultMessage: 'Отмена',
    },
    eventFormNameEdit: {
        id: 'EventDialog.eventFormNameEdit',
        defaultMessage: 'Редактировать событие',
    },
    eventNameLabel: {
        id: 'EventDialog.eventNameLabel',
        defaultMessage: 'Название события',
    },
    eventNamePlaceholder: {
        id: 'EventDialog.eventNamePlaceholder',
        defaultMessage: 'Введите название события',
    },
    eventStrategyLabel: {
        id: 'EventDialog.eventStrategyLabel',
        defaultMessage: 'Стратегия',
    },
    eventActionsLabel: {
        id: 'EventDialog.eventActionsLabel',
        defaultMessage: 'Действие',
    },
    eventUserIdLabel: {
        id: 'EventDialog.eventUserIdLabel',
        defaultMessage: 'Пользователь',
    },
    eventGroupIdLabel: {
        id: 'EventDialog.eventGroupIdLabel',
        defaultMessage: 'Группа',
    },
    eventNodeIdLabel: {
        id: 'EventDialog.eventNodeIdLabel',
        defaultMessage: 'Элемент',
    },
    eventParentNodeIdLabel: {
        id: 'EventDialog.eventParentNodeIdLabel',
        defaultMessage: 'Элемент родитель',
    },
    eventClassNameLabel: {
        id: 'EventDialog.eventClassNameLabel',
        defaultMessage: 'Обработчик',
    },
    eventParamsLabel: {
        id: 'EventDialog.eventParamsLabel',
        defaultMessage: 'Параметры',
    },
    eventParamsPlaceholder: {
        id: 'EventDialog.eventParamsPlaceholder',
        defaultMessage: 'Введите название параметров',
    },
    eventScriptIdLabel: {
        id: 'EventDialog.eventScriptIdLabel',
        defaultMessage: 'Скрипт',
    },
    eventScriptIdPlaceholder: {
        id: 'EventDialog.eventScriptIdPlaceholder',
        defaultMessage: 'Введите идентификатор скрипта',
    },
    eventRequiredFields: {
        id: 'EventDialog.eventRequiredFields',
        defaultMessage: 'Обязательные поля должны быть заполнены',
    },

    eventlaunchingScriptUserIdLabel: {
        id: 'EventDialog.eventlaunchingScriptUserIdLabel',
        defaultMessage: 'Пользователь, запускающий скрипт',
    },
    BEFORE: {
        id: 'EventDialog.BEFORE',
        defaultMessage: 'До',
    },
    AFTER: {
        id: 'EventDialog.AFTER',
        defaultMessage: 'После',
    },
    ERROR: {
        id: 'EventDialog.ERROR',
        defaultMessage: 'В случае ошибки',
    },
    INSTEAD: {
        id: 'EventDialog.INSTEAD',
        defaultMessage: 'Вместо',
    },
    nameHint: {
        id: 'EventDialog.nameHint',
        defaultMessage: 'Наименование правила по обработке события',
    },
    strategyHint: {
        id: 'EventDialog.strategyHint',
        defaultMessage: 'Момент или ситуация, требующие обработки события',
    },
    actionHint: {
        id: 'EventDialog.actionHint',
        defaultMessage: 'Действие, порождающее событие',
    },
    userHint: {
        id: 'EventDialog.userHint',
        defaultMessage:
            'Пользователь, который инициирует событие. Если поле пустое, будет обработано событие, инициированное любым пользователем, входящим в группу из поля "Группа"',
    },
    groupHint: {
        id: 'EventDialog.groupHint',
        defaultMessage:
            'Группа, в которую входит пользователь, который инициирует событие. Если поле пустое, будет обработано событие, инициированное пользователем, указанным в поле "Пользователь"',
    },
    elementHint: {
        id: 'EventDialog.elementHint',
        defaultMessage:
            'Элемент, к которому относится событие. Если поле пустое, будет обработано событие по элементу, дочернему для указанного в поле "Элемент-родитель"',
    },
    elementParentHint: {
        id: 'EventDialog.elementParentHint',
        defaultMessage:
            'Вышестоящий элемент, прямо или косвенно включающий в структуре репозитория тот элемент, к которому относится событие. Если поле пустое, будет обработано событие по элементу, указанному в поле "Элемент"',
    },
    eventHandlerHint: {
        id: 'EventDialog.eventHandlerHint',
        defaultMessage:
            'Название обработчика события. Штатный обработчик: ru.nextconsulting.bpm.eventhandlers.MacrosScriptHandler',
    },
    parametersHint: {
        id: 'EventDialog.parametersHint',
        defaultMessage: 'Дополнительные параметры, которые могут быть переданы в обработчик для настройки его работы',
    },
    scriptHint: {
        id: 'EventDialog.scriptHint',
        defaultMessage: 'Скрипт, который будет выполнен в результате обработки события',
    },
    userLaunchingScriptHint: {
        id: 'EventDialog.userLaunchingScriptHint',
        defaultMessage:
            'Пользователь, который инициирует выполнение скрипта, который запускается по событию. Если поле пустое, скрипт будет запущен от пользователя, который инициировал событие',
    },
});
