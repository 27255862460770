import { Form, Select } from 'antd';
import React from 'react';
import { PrincipalDescriptor } from '../../../../serverapi/api';

type TPrincipalSelectProps = {
    initPrincipal: number | string | undefined;
    label: string | React.ReactNode;
    principalList: PrincipalDescriptor[];
    formItemName: string;
    dataTest?: string;
};

export const PrincipalSelect = (props: TPrincipalSelectProps) => {
    const { initPrincipal, label, principalList, formItemName, dataTest } = props;

    return (
        <Form.Item label={label} name={formItemName} initialValue={initPrincipal} data-test={dataTest}>
            <Select allowClear>
                {principalList.map((principal) => {
                    return (
                        <Select.Option key={principal.id} value={principal.id}>
                            {principal.login}
                        </Select.Option>
                    );
                })}
            </Select>
        </Form.Item>
    );
};
